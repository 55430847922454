<script>
	import GlobalVue from '@helper/Global.vue';
	import Gen from '@helper/Gen';
	import TopBarVue from './TopBar.vue';

	export default {
		extends: GlobalVue,
		data() {
			return {}
		},
		async mounted() {
			await this.ready();
			if (typeof SEMICOLON != "undefined") SEMICOLON.slider.init()
			// AOS.init();
		},
		computed: {
			isLogin() {
				return this.user && Gen.userToken() ? true : false
			},
			ufullname() {
				return this.user ? this.user.fullname : "-"
			},
			isNotDesktop(){
                return this.isMobile || this.isTablet ? true : false
            }
		},
		methods: {
			showMenu() {
				if (!this.isNotDesktop) return
				$('#primary-menu-trigger').toggleClass("show-menu");
				$('#primary-menu').toggleClass("show-menu");
			},
			closeMenu() {
				if (!this.isNotDesktop) return
				$('#primary-menu-trigger').toggleClass("show-menu");
				$('#primary-menu').toggleClass("show-menu");
			},
			removeMenu(){
				$("#primary-menu").removeClass("show-menu");
				$("#primary-menu-trigger").removeClass("show-menu");
			},
			changeLang(hl){
                // window.location.href = changeLangLink(hl);
            },
            changeLangLink(hl) {
                window.location = this.$route.path + "?hl="+hl
            },
		},
		watch: {
			$route() {
			}
		}
	};
</script>

<template>
	<header id="header" class="transparent-header">
		<div id="header-wrap">
			<div class="container clearfix">
				<div id="primary-menu-trigger" @click="showMenu">
					<i class="icon-line-menu"></i>
					<i class="icon-line-cross"></i>
				</div>
				<router-link v-if="!isLogin" :to="{name:'Login'}" class="user-login d-lg-none">
					<span @click="removeMenu">{{web.lbl_signin_capital}}</span><i class="icon-user"></i>
				</router-link>
				<div id="mobLogo" class="d-lg-none" v-if="$root.myBaseHost.indexOf('capediscovery')>-1">
					<img :src="assets('fo_images','cape-menu-logo.png')" alt="">
					<h1 style="display:none">Cape Discovery</h1>
					<img :src="assets('fo_images','belli-menu-logo.png')" alt="">
				</div>
				<div id="mobLogo" class="d-lg-none" v-if="$root.myBaseHost.indexOf('bellissimo')>-1">
					<img :src="assets('fo_images','belli-menu-logo.png')" alt=""
					><h1 style="display:none">Bellissimo</h1>
					<img :src="assets('fo_images','cape-menu-logo.png')" alt="">
				</div>

				<nav id="primary-menu">
					<ul>
						<li @click="closeMenu">
							<router-link :to="{name:'index'}" class="home d-none d-lg-block pl-lg-0">
								<div><i class="icon-home"></i></div>
							</router-link>
							<router-link :to="{name:'index'}" class="d-lg-none">
								<div>{{web.lbl_home}}</div>
							</router-link>
						</li>

						<template v-if="$root.myBaseHost.indexOf('capediscovery')>-1">
							<li class="ml-0">
								<a href="#" class="menu-img">
									<div class="menu-img-img"><img :src="assets('fo_images','cape-menu-logo.png')"
											alt="Our Cape Discovery"><h1 style="display:none">Cape Discovery</h1>
									</div>
									<div class="menu-img-text">{{web.lbl_our_cape_discovery}}</div>
								</a>
								<ul>
									<li @click="closeMenu">
										<router-link :to="{name:'CDProses'}">
											<div>{{web.lbl_the_proses}}</div>
										</router-link>
									</li>
									<li @click="closeMenu">
										<router-link :to="{name:'CDCollection'}">
											<div>{{web.lbl_the_collection}}</div>
										</router-link>
									</li>
									<li @click="closeMenu">
										<router-link :to="{name:'CDHowToEnjoy'}">
											<div>{{web.lbl_how_to_enjoy}}</div>
										</router-link>
									</li>
									<li @click="closeMenu" v-if="$root.subscribeActive=='true'">
										<router-link :to="{name:'Subscribe'}">
											<div>{{web.lbl_subscription}}</div>
										</router-link>
									</li>
								</ul>
							</li>
							<li>
								<a href="#" class="menu-img">
									<div class="menu-img-img"><img :src="assets('fo_images', 'belli-menu-logo.png')" alt="Our Bellissimo"><h1 style="display:none">Bellissimo</h1>
									</div>
									<div class="menu-img-text">{{web.lbl_our_bellissimo}}</div>
								</a>
								<ul>
									<li @click="closeMenu">
										<router-link :to="{name:'BLProses'}">
											<div>{{web.lbl_the_proses}}</div>
										</router-link>
									</li>
									<li @click="closeMenu">
										<router-link :to="{name:'BLCollection'}">
											<div>{{web.lbl_the_collection}}</div>
										</router-link>
									</li>
									<li @click="closeMenu">
										<router-link :to="{name:'BLHowToEnjoy'}">
											<div>{{web.lbl_how_to_enjoy}}</div>
										</router-link>
									</li>
									<li @click="closeMenu" v-if="$root.subscribeActive=='true'">
										<router-link :to="{name:'Subscribe'}">
											<div>{{web.lbl_subscription}}</div>
										</router-link>
									</li>
								</ul>
							</li>
						</template>
						<template v-if="$root.myBaseHost.indexOf('bellissimo')>-1">
							<li class="ml-0">
								<a href="#" class="menu-img">
									<div class="menu-img-img"><img :src="assets('fo_images', 'belli-menu-logo.png')" alt="Our Bellissimo"><h1 style="display:none">Bellissimo</h1>
									</div>
									<div class="menu-img-text">{{web.lbl_our_bellissimo}}</div>
								</a>
								<ul>
									<li @click="closeMenu">
										<router-link :to="{name:'BLProses'}">
											<div>{{web.lbl_the_proses}}</div>
										</router-link>
									</li>
									<li @click="closeMenu">
										<router-link :to="{name:'BLCollection'}">
											<div>{{web.lbl_the_collection}}</div>
										</router-link>
									</li>
									<li @click="closeMenu">
										<router-link :to="{name:'BLHowToEnjoy'}">
											<div>{{web.lbl_how_to_enjoy}}</div>
										</router-link>
									</li>
									<li @click="closeMenu" v-if="$root.subscribeActive=='true'">
										<router-link :to="{name:'Subscribe'}">
											<div>{{web.lbl_subscription}}</div>
										</router-link>
									</li>
								</ul>
							</li>
							<li>
								<a href="#" class="menu-img">
									<div class="menu-img-img"><img :src="assets('fo_images','cape-menu-logo.png')"
											alt="Our Cape Discovery"><h1 style="display:none">Cape Discovery</h1>
									</div>
									<div class="menu-img-text">{{web.lbl_our_cape_discovery}}</div>
								</a>
								<ul>
									<li @click="closeMenu">
										<router-link :to="{name:'CDProses'}">
											<div>{{web.lbl_the_proses}}</div>
										</router-link>
									</li>
									<li @click="closeMenu">
										<router-link :to="{name:'CDCollection'}">
											<div>{{web.lbl_the_collection}}</div>
										</router-link>
									</li>
									<li @click="closeMenu">
										<router-link :to="{name:'CDHowToEnjoy'}">
											<div>{{web.lbl_how_to_enjoy}}</div>
										</router-link>
									</li>
									<li @click="closeMenu" v-if="$root.subscribeActive=='true'">
										<router-link :to="{name:'Subscribe'}">
											<div>{{web.lbl_subscription}}</div>
										</router-link>
									</li>
								</ul>
							</li>
						</template>
						<li :class="$route.name=='Story' || $route.name=='Blog' || $route.name=='Cities' ? 'current' :''">
							<a href="#">
								<div>{{web.lbl_meet_us}}</div>
							</a>
							<ul>
								<li @click="closeMenu">
									<router-link :to="{name:'Story'}">
										<div>{{web.lbl_our_story}}</div>
									</router-link>
								</li>
								<li @click="closeMenu">
									<router-link :to="{name:'Blog'}">
										<div>{{web.lbl_our_blog}}</div>
									</router-link>
								</li>
								<li @click="closeMenu">
									<router-link :to="{name:'Cities'}">
										<div>{{web.lbl_our_cities}}</div>
									</router-link>
								</li>
							</ul>
						</li>
						<li @click="closeMenu" :class="$route.name=='ContactUs' ? 'current' :''">
							<router-link :to="{name:'ContactUs'}">
								<div>{{web.lbl_talk_to_us}}</div>
							</router-link>
						</li>
						<li :class="$route.name=='Subscribe' ? 'ml-lg-1 current':'ml-lg-1'" @click="closeMenu"
							v-if="$root.subscribeActive=='true'">
							<router-link :to="{name:'Subscribe'}" class="text-red">
								<div>{{web.lbl_subscribe}}</div>
							</router-link>
						</li>
						<li class="language ml-lg-0" :class="$root.subscribeActive=='true'?'d-lg-block':'d-none d-lg-block'">
							<a href="#">
								<div><img :src="assets('fo_images','flag/'+hl+'.png')" :alt="hl=='en'?'English':'Bahasa'"
										class="mr-2 mr-lg-0"><span class="d-inline-block d-lg-none" v-if="hl=='en'">English</span><span
										class="d-inline-block d-lg-none" v-else>Bahasa</span></div>
							</a>
							<ul>
								<li><a href="javascript:;" @click="changeLangLink('en')">
										<div><img :src="assets('fo_images','flag/en.png')" alt="English"> English</div>
									</a></li>
								<li><a href="javascript:;" @click="changeLangLink('id')">
										<div><img :src="assets('fo_images','flag/id.png')" alt="Indonesia"> Bahasa</div>
									</a></li>
							</ul>
						</li>
						<li v-if="!isLogin" class="float-lg-right header-cta d-none d-lg-block">
							<router-link :to="{name:'Login'}">
								<div class="button button-red">{{web.lbl_signin}}</div>
							</router-link>
						</li>
						<li @click="closeMenu" v-show="isLogin" id="menuLogin" :class="isLogin?'float-lg-right d-lg-block sub-menu':''">
							<a href="#" v-show="isLogin">
								<div>{{ufullname}}</div>
							</a>
							<ul>
								<li>
									<router-link :to="{name:'Dashboard'}">
										<div>{{web.lbl_dashboard}}</div>
									</router-link>
								</li>
								<li>
									<router-link :to="{name:'logout'}">
										<div>{{web.lbl_singout}}</div>
									</router-link>
								</li>
							</ul>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</header>
</template>